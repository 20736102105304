import './global.scss';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import {
  AppInsightsErrorBoundary,
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ThemeProvider } from '@mui/material';
import { createBrowserHistory } from 'history';
import React, { Suspense, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { pdfjs } from 'react-pdf';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import history from 'src/app/router/utils/history';
import { Auth0ProviderWithNavigate } from 'src/Auth0Provider';
import { generateAzureStorageBlobToken } from 'src/helper/utilityFunctions';
import { setSaasToken } from 'src/redux/store/slices/auth';
import { theme } from 'src/theme';

import NoServer from './app/public/pages/noServer';
import getRouteConfigs from './app/router/config/base.route';
import generateRoutesFromConfig from './app/router/utils/generateRoutesFromConfig';
import store, { persistor } from './redux/store/index';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const browserHistory = createBrowserHistory({ basename: window.location.origin });

let clickPluginInstance = new ClickAnalyticsPlugin();
let clickPluginConfig = {
  autoCapture: true,
};

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: `instrumentationKey=${process.env.REACT_APP_INSIGHTS}`,
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: [
      '*.auth0.com',
      '*.googleapis.com',
      '*.blob.core.windows.net',
    ],
    extensions: [reactPlugin, clickPluginInstance],

    [clickPluginInstance.identifier]: clickPluginConfig,

    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsights.loadAppInsights();

const Main = () => {
  const { i18n } = useTranslation();

  const currentLanguage = useSelector((state) => state.auth.currentLanguage);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const dispatch = useDispatch();

  useEffect(() => {
    i18n.changeLanguage(currentLanguage?.code);
  }, []);

  useEffect(() => {
    if (accessToken) {
      (async () => {
        const saasToken = await generateAzureStorageBlobToken({
          permissions: ['w', 'r', 'd'],
        });

        dispatch(setSaasToken(saasToken));
      })();
    }
  }, [accessToken]);

  const configs = useMemo(getRouteConfigs, []);
  // Generate and return routes from configs
  return useMemo(() => generateRoutesFromConfig(configs), [configs]);
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <AppInsightsErrorBoundary onError={NoServer} appInsights={reactPlugin}>
              <Suspense fallback={null}>
                <Auth0ProviderWithNavigate>
                  <Main />
                </Auth0ProviderWithNavigate>
              </Suspense>
            </AppInsightsErrorBoundary>
          </Router>
        </PersistGate>
      </Provider>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default withAITracking(reactPlugin, App);
